import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";

export const base_url_2 = "https://digi.pmpframe.com/";

export const get_background_image = createAsyncThunk(
  "background",
  async (id) => {
    try {
      const body = new FormData();
      body.append("action", "list");
      body.append("type", "web");
      //   body.append("userid", user.userid);
      const response = await fetch(base_url + "image.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      return res;
    } catch (error) {}
  }
);

export const get_video = createAsyncThunk("video", async () => {
  const body = new FormData();
  body.append("action", "list");
  const response = await fetch(base_url + "video.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const otherSlice = createSlice({
  name: "claim",
  initialState: {
    background_img: "",
    video_list: [],
  },
  extraReducers: (builder) => {
    builder.addCase(get_background_image.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        const filterData = data?.data?.filter((v, i) => v?.Status == "1");
        state.background_img = filterData[0]?.Img_url;
      } else {
        state.background_img = "";
      }
    });
    builder.addCase(get_video.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.video_list = data.data;
      } else {
        state.video_list = [];
      }
    });
  },
});
export default otherSlice.reducer;
